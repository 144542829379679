import React, { useState, useEffect } from "react";
import { Formik, Form, Field,ErrorMessage } from "formik";
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import { SubAdminSchema } from "../../Helpers/Constants/Schemas";
import { SubAdminFields } from "../../Helpers/Constants/InitialValues";
import { EndPoints } from "../../Helpers/Constants/EndPoints";
import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import { CREATE, UPDATE } from "../../Helpers/Constants/Default";
import { UpdateParam } from "../../Helpers/Universal/Function/common";
import { LIMIT1, NA, OFFSET } from "../../Helpers/Constants/Default";
import { Country, State, City } from 'country-state-city';
import CustomSelect from "./CustomSelect";
const Admin = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isUpdate] = useState(state ? true : false);
  const [initialValues] = useState(isUpdate ? state : SubAdminFields.required);

  const paginate = useSelector((val) => val.paginate);
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState({ loader: false });
  const [filter, setFilter] = useState({
    limit: LIMIT1,
    offset: paginate?.subAdmin || OFFSET,
  });

  useEffect(() => {
    const city = City.getCitiesOfState("IN")
    console.log(city);
  }, [])

  const [admindata, setAdminData] = useState([]);

  const [employees, setEmployees] = useState([]);
  const [selectedemployees, setSelectedEmployees] = useState([]);

  const [managers, setManagers] = useState({});
  const [managersname, setManagersname] = useState([]);
  
  const [selectedManager, setSelectedManager] = useState(null);

  const getData = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));

      const { status, data } = await Axios.get(API.SUPERADMIN.PARENT, {
      
      });
      console.log(data.managers)
      console.log(data)
      console.log(status)
      if (status === true) {
        setData(data);
        setManagers(data || []);
        setManagersname(data.name)
        console.log(data.name)
        console.log(managers)
      }

    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };

  const getAdminData = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));

      const { status, data } = await Axios.get(API.MANAGER.LISTING, {
        limit: filter.limit,
        offset: filter.offset,
      });
      console.log(admindata.managers)
      console.log(admindata)
      console.log(status)
      if (status === true) {
        setAdminData(data);
        setEmployees(data.data || []);
      }

    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };

  useEffect(() => {
    console.log("Filter changed:", filter);
    getData();
  }, [filter]);

  useEffect(() => {
    console.log("Filter changed:", filter);
    getAdminData();
  }, [filter]);

  const handleManagerChange = (event) => {
    const selectedManagerObject = event.target.value;
    console.log(event.target.value)
    setSelectedManager(selectedManagerObject);
  };

  const handleEmployeeChange = (event) => {
    const selectedValue = event.target.value;
    const selectedEmployeeObject = employees.find(emp => emp._id === selectedValue);

    if (selectedEmployeeObject) {
      setSelectedEmployees(prevSelected => {
        if (prevSelected.some(emp => emp._id === selectedValue)) {
          return prevSelected.filter(emp => emp._id !== selectedValue);
        } else {
          return [...prevSelected, selectedEmployeeObject];
        }
      });
    }
  };

  const [statesAndUTs, setStatesAndUTs] = useState([]);

  const [customState, setCustomState] = useState('');

  const [cities, setCities] = useState([]);
  const [newCity, setNewCity] = useState('');
  const [state1, setState1] = useState("");
  const [city1, setCity1] = useState("");
  const [state2, setState2] = useState([]);
  const [city, setCity] = useState([]);

  useEffect(() => {
    setStatesAndUTs(State.getStatesOfCountry("IN"))
  }, [])

  const handleAddState = () => {
    if (customState && !statesAndUTs.some(state => state.name === customState)) {
      setStatesAndUTs([...statesAndUTs, { name: customState }]);
      setCustomState('');
    }
  };

  const handleAddCity = () => {
    if (newCity && !cities.some(city => city.name === newCity)) {
      setCities([...cities, { name: newCity }]);
      setNewCity('');
    }
  };

  const typeToRoute = {
    SUPERADMIN: {
      create: EndPoints.ADD_SUPER_ADMIN,
      update: EndPoints.EDIT_SUPER_ADMIN,
    },
    ADMIN: {
      create: EndPoints.ADD_ADMIN,
      update: EndPoints.EDIT_ADMIN,
    },
    SUBADMIN: {
      create: EndPoints.ADD_SUB_ADMIN,
      update: EndPoints.EDIT_SUB_ADMIN,
    },
    MANAGER: {
      create: EndPoints.ADD_MANAGER,
      update: EndPoints.EDIT_MANAGER,
    },
    SUPERVISOR: {
      create: EndPoints.ADD_SUPERVISOR,
      update: EndPoints.EDIT_SUPERVISOR,
    },
    AGENT: {
      create: EndPoints.ADD_AGENT,
      update: EndPoints.EDIT_AGENT,
    },
  };


  const handleSubmit = async (data) => {
    const { address, state, city, pincode, ...rest } = data;

    const payload = {
      ...rest,
      address: {
        address: address,
        state: state2 || customState,
        city,
        pincode,
        country: "India",
      },
    };

    if (selectedManager) {
      payload.parentId = selectedManager;
    }

    if (selectedemployees.length > 0) {
      payload.userList = selectedemployees.map(emp => emp._id);
    }

    try {
      const { status } = await Axios.post(API.ADMIN.CREATE, payload);
      if (status) {
        navigate(typeToRoute[data.type].create);
      }
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const handleUpdateDocumentAdmin = async (data) => {
    const { address, state, city, pincode, ...rest } = data;
  
    // Initialize the address object
    const addressObject = {};
  
    // Conditionally add properties to the address object
    if (address) {
      addressObject.address = address;
    }
    if (state) {
      addressObject.state = state2 || customState; // Assuming you have state2 and customState defined
    }
    if (city) {
      addressObject.city = city;
    }
    if (pincode) {
      addressObject.pincode = pincode;
    }
  
    // Only add country if any other address field is present
    if (Object.keys(addressObject).length > 0) {
      addressObject.country = "India"; // Add country only if other fields exist
    }
  
    const params = {
      subAdminId: initialValues._id,
      name: initialValues.name,
      email: initialValues.email,
      password: initialValues.password,
      isBlocked: initialValues.isBlocked,
      roleId: initialValues.roleId._id,
      permissions: initialValues.roleId.permissions,
    };
  
    // Only include the address object if it has any fields
    if (Object.keys(addressObject).length > 0) {
      params.address = addressObject;
    }
  
    if (selectedManager) {
      params.parentId = selectedManager;
    }
  
    // Only add userList if employees are selected
    if (selectedemployees.length > 0) {
      params.userList = selectedemployees.map(emp => emp._id);
    }
  
    try {
      const { status } = await Axios.patch(API.ADMIN.UPDATE, params);
      if (status) {
        navigate(typeToRoute[data.type].update);
      }
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };
  const [selectedState, setSelectedState] = useState('');
  const [cityOptions, setCityOptions] = useState([]);

  const handleStateChange = (event) => {
    const [state, name] = event.target.value.split("_");
    console.log(state)
    setSelectedState(City.getCitiesOfState("IN", state));
    setState2(name)
    console.log(name)
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SubAdminSchema}
      onSubmit={(values, { resetForm }) => {
        isUpdate ? handleUpdateDocumentAdmin(values) : handleSubmit(values);
        // resetForm();
      }}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form className="page-from">
          <div className="inner-form">
            <div className="row">
              <div className="col-md-4 mb-3">
                <label>Type</label>
                <Field as="select" className="form-control" name="type" onChange={(event) => {
                  const valueType = event.target.value;
                  const route = typeToRoute[valueType];
                  if (isUpdate) {
                    navigate(route.update);
                  } else {
                    navigate(route.create);
                  }
                }}>
                  <option value="ADMIN" label="REGIONAL HEAD/ADMIN" />
                  <option value="SUPERADMIN" label="ROOT USER/SUPERADMIN" />
                  <option value="SUBADMIN" label="AREA HEAD/SUBADMIN" />
                  <option value="MANAGER" label="MANAGER" />
                  <option value="SUPERVISOR" label="SUPERVISOR" />
                  <option value="AGENT" label="AGENT" />
                </Field>
                {errors.type && touched.type ? (
                  <div className="error">{errors.type}</div>
                ) : null}
              </div>

              <div className="col-md-4 mb-3">
                <label>Name</label>
                <Field className="form-control" type="text" name="name" />
                {errors.name && touched.name ? (
                  <div className="error">{errors.name}</div>
                ) : null}
              </div>

              <div className="col-md-4 mb-3">
                <label>Email</label>
                <Field className="form-control" type="email" name="email" />
                {errors.email && touched.email ? (
                  <div className="error">{errors.email}</div>
                ) : null}
              </div>

              <div className="col-md-4 mb-3">
                <label>Password</label>
                <Field className="form-control" type="text" name="password" />
                {errors.password && touched.password ? (
                  <div className="error">{errors.password}</div>
                ) : null}
              </div>
              <div className="col-md-4 mb-3">
                <label>Address</label>
                <Field className="form-control" type="text" name="address" />
                {errors.address && touched.address ? (
                  <div className="error">{errors.address}</div>
                ) : null}
              </div>

              <div className="col-md-4 mb-3">
                <label htmlFor="state">State</label>
                <Field as="select" className="form-control" name="state" onChange={handleStateChange}>
                  <option value="" label="Select state" />
                  {statesAndUTs.map((state, key) => (
                    <option key={key} value={state.isoCode + "_" + state.name}>{state.name}

                    </option>
                  ))}
                </Field>
                <ErrorMessage name="state" component="div" className="error" />

                {/* <div className="mt-2">
                  <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="Add your State"
                    value={customState}
                    onChange={(e) => setCustomState(e.target.value)}
                  />
                  <button type="button" className="btn btn-primary" onClick={handleAddState}>
                    Add State
                  </button>
                </div> */}
              </div>

{/* 
              <div className="col-md-4 mb-3">
                <label>State Code</label>
                <Field className="form-control" type="text" name="stateCode" />
                {errors.stateCode && touched.stateCode ? (
                  <div className="error">{errors.stateCode}</div>
                ) : null}
              </div> */}

              <div className="col-md-4 mb-3">
                <label>Pin Code</label>
                <Field className="form-control" type="text" name="pincode" />
                {errors.pincode && touched.pincode ? (
                  <div className="error">{errors.pincode}</div>
                ) : null}
              </div>

            


              <div className="col-md-4 mb-3">
                <label htmlFor="manager">Reporting Manager</label>
                <Field as="select" className="form-control" name="manager" onChange={handleManagerChange}>
                  <option value="" label="Select manager" />
                                      <option key={managers?._id} value={managers?._id}>{managers?.name}</option>
                  
                </Field>
                {errors.manager && touched.manager ? (
                  <div className="error">{errors.manager}</div>
                ) : null}
              </div>
              <div className="col-md-4 mb-3">
                <label>City</label>
                <Field as="select" name="city" className="form-control">
                  <option value="">Select a city</option>
                  {selectedState && selectedState.map((city, index) => (
                    <option key={index} value={city.name}>
                      {city.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="city" component="div" className="error" />

                {/* <div className="mt-2">
                  <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="Add your city"
                    value={newCity}
                    onChange={(e) => setNewCity(e.target.value)}
                  />
                  <button type="button" className="btn btn-primary" onClick={handleAddCity}>
                    Add City
                  </button>
                </div> */}
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="userList">Select Managers</label>
                <Field
                  name="userList"
                  options={employees.map(employee => ({ label: employee.name, value: employee._id }))}
                  component={CustomSelect}
                  placeholder="Select Managers..."
                  isMulti={true}
                />
              </div>


              <div className="col-md-12 mb-3">
                <button
                  disabled={isSubmitting}
                  className="btn bg-gradient-dark mt-3 d-block btn-lg mb-1"
                  type="submit"
                >
                  {isUpdate ? UPDATE : CREATE}
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Admin;
