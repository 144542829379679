import React from 'react';
import Select from 'react-select';

class CustomSelect extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(option) {
    this.props.form.setFieldValue(
      this.props.field.name,
      this.props.isMulti
        ? option.map((item) => item.value)
        : option.value
    );
  }

  getValue() {
    if (this.props.options && this.props.field.value) {
      return this.props.isMulti
        ? this.props.options.filter((option) => this.props.field.value.indexOf(option.value) >= 0)
        : this.props.options.find((option) => option.value === this.props.field.value);
    } else {
      return this.props.isMulti ? [] : '';
    }
  }

  render() {
    return (
      <Select
        className={this.props.className}
        name={this.props.field.name}
        value={this.getValue()}
        onChange={this.onChange}
        placeholder={this.props.placeholder}
        options={this.props.options}
        isMulti={this.props.isMulti}
      />
    );
  }
}

export default CustomSelect;