import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

import { EndPoints } from "../../Helpers/Constants/EndPoints";
import Loader from "../Common/Loader";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import {
  BLOCK_STATUS,
  LIMIT,
  NA,
  OFFSET,
  USER_TYPE,
} from "../../Helpers/Constants/Default";
import Paginate from "../Common/Paginate";
import DatePicker from "../Common/DatePicker";
import DeleteConfirmation from "../Common/DeleteConfirmation ";
import { useSelector } from "react-redux";
import DataNotFound from "../Common/DataNotFound";

const Agents = ({ permissions: { all, view, add, edit, delete: del } }) => {
  const paginate = useSelector((val) => val.paginate);
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState({
    loader: false,
    deleteConformation: { data: false, visible: false },
  });
  const [dropdown, setDropdown] = useState({ supervisors: [], managers: [] });
  const [filter, setFilter] = useState({
    limit: LIMIT,
    offset: paginate?.agent || OFFSET,
    // offset:OFFSET
  });

  const getData = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));
      //  console.log(filter)
      const { status, data } = await Axios.get(API.AGENT.LISTING, filter);
      console.log(data);
      if (status) setData(data);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };

  const handleBulkDelete = async () => {
    try {
      Swal.fire({
        title: `Delete All Data`,
        text: `Are you sure you want to Delete all Agents Data?`,
        confirmButtonText: "Yes",
        showCancelButton: true,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            setVisible((prev) => ({ ...prev, loader: true }));
            await Axios.delete(API.AGENT.BULK_DELETE);
          }
        })
        .finally(() => {
          setVisible((prev) => ({ ...prev, loader: false }));
        });
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };

  const handleBlockUnblock = async ({ _id, isBlocked }) => {
    try {
      Swal.fire({
        title: `${isBlocked ? "Block" : "Un-block"} Agent`,
        text: `Are you sure you want to ${isBlocked ? "Block" : "Un-block"}?`,
        confirmButtonText: "Yes",
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { status } = await Axios.patch(API.AGENT.UPDATE, {
            agentId: _id,
            isBlocked: !isBlocked,
          });
          if (status)
            setData((prev) => ({
              ...prev,
              data: prev?.data?.map((val) =>
                val._id === _id ? { ...val, isBlocked: !isBlocked } : val
              ),
            }));
        }
      });
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const handleResetPassword = async (agentId) => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));
      await Axios.get(API.AGENT.RESET_PASSWORD + "/" + agentId);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };

  const handleDelete = async (data) => {
    try {
      setVisible((prev) => ({
        ...prev,
        deleteConformation: { visible: true, data },
      }));
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const handleAction = async (agentId) => {
    setData((prev) => ({
      ...prev,
      data: prev?.data?.map((val) =>
        val._id === agentId && !val?.action
          ? { ...val, action: true }
          : { ...val, action: false }
      ),
    }));
  };

  const getDropdowns = async () => {
    try {
      const [supervisor, manager] = await Promise.all([
        Axios.get(API.DROPDOWN.USER, {
          accountType: USER_TYPE.SUPERVISOR,
        }),
        Axios.get(API.DROPDOWN.USER, {
          accountType: USER_TYPE.MANAGER,
        }),
      ]);

      if (supervisor.status)
        setDropdown((prev) => ({ ...prev, supervisors: supervisor.data }));
      if (manager.status)
        setDropdown((prev) => ({ ...prev, managers: manager.data }));
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const setFilters = (event, key) => {
    const value = event?.target?.value;

    value
      ? setFilter((prev) => ({ ...prev, [key]: value }))
      : setFilter((prev) => {
          delete prev[key];
          return { ...prev };
        });
  };

  const exportExcel = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));
      const { status, data } = await Axios.get(API.REPORT.AGENT, filter);
      if (status) window.open(data, "_blank");
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };

  const handleBlur = () => {
    setData((prev) => ({
      ...prev,
      data: prev?.data?.map((val) => ({
        ...val,
        action: false,
      })),
    }));
  };

  useEffect(() => {
    getData();
  }, [filter]);

  useEffect(() => {
    getDropdowns();
  }, []);

  return (
    <Fragment>
      {visible.loader && <Loader />}
      {visible.deleteConformation?.visible && (
        <DeleteConfirmation
          data={visible.deleteConformation?.data}
          setVisible={setVisible}
          setData={setData}
          filter={filter}
          GETAPI={API.AGENT.LISTING}
          API={API.AGENT.DELETE}
        />
      )}
      <div className="container-fluid py-3">
        <div className="dateselect_navigation d-flex justify-content-between align-items-center">
          <div className="d-flex main_bar">
            <DatePicker filter={filter} setFilter={setFilter} />
            <select
              className="form-select text-start btn bg-gradient-dark btn-md mb-0 mx-2"
              onChange={(e) => setFilters(e, "supervisorId")}
            >
              <option value="">Supervisors</option>
              {dropdown.supervisors?.map((val) => (
                <option key={val._id} value={val._id}>
                  {val.name}
                </option>
              ))}
            </select>
            <select
              className="form-select text-start btn bg-gradient-dark btn-md mb-0 mx-2"
              onChange={(e) => setFilters(e, "managerId")}
            >
              <option value="">Managers</option>
              {dropdown.managers?.map((val) => (
                <option key={val._id} value={val._id}>
                  {val.name}
                </option>
              ))}
            </select>
            <div className="d-flex position-relative bg-gradient-dark rounded">
              <i
                className="fa fa-search position-absolute top-50 translate-middle ms-3 text-white"
                aria-hidden="true"
              ></i>
              <input
                onChange={(e) => setFilters(e, "search")}
                type="search"
                className="text-start"
                style={{ cursor: "unset" }}
                name="gsearch"
              ></input>
            </div>
          </div>
          <div className="d-flex align-items-center mob-bb">
            <button
              className="btn bg-gradient-dark float-end d-flex btn-md mb-1 align-items-center h-52 data-del"
              onClick={handleBulkDelete}
            >
              Delete All data
            </button>
            <div className="me-2 mob-margin-none">
              {(all || add) && (
                <Link to={EndPoints.ADD_AGENT}>
                  <button className="btn bg-gradient-dark float-end d-flex btn-md mb-1 align-items-center h-52">
                    <i className="fa fa-plus me-2 mt-1" aria-hidden="true"></i>
                    Add
                  </button>
                </Link>
              )}
            </div>
            <button
              className="btn bg-gradient-dark float-end d-flex btn-md mb-1 align-items-center h-52 data-del"
              onClick={exportExcel}
            >
              <i className="fa fa-download me-2 mt-1" aria-hidden="true"></i>
              Export
            </button>
          </div>
        </div>

        {data?.data?.length === 0 ? (
          <DataNotFound />
        ) : (
          <Fragment>
            <div className="overflow-table">
              <table className="page-table">
                <thead>
                  <tr>
                    <th scope="col" className="text-uppercase">
                      #
                    </th>
                    <th scope="col" className="text-uppercase">
                      Profile
                    </th>
                    <th scope="col" className="text-uppercase">
                      User Id
                    </th>
                    <th scope="col" className="text-uppercase">
                      Name
                    </th>
                    <th scope="col" className="text-uppercase">
                      Phone Number
                    </th>
                    <th scope="col" className="text-uppercase">
                      Supervisor
                    </th>
                    {/* <th scope="col" className="text-uppercase">
                  Manager
                </th> 
                 <th scope="col" className="text-uppercase">
                  Blocked
                </th>
                <th scope="col" className="text-uppercase">
                  Date
                </th> */}
                    {(all || edit || del) && (
                      <th scope="col" className="text-uppercase">
                        Action
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.map((val, i) => (
                    <tr key={val._id}>
                      <td>{++i + filter.offset}</td>
                      <td>
                        {val.image ? (
                          <img
                            className="profile"
                            src={val.image}
                            alt={val.name}
                          />
                        ) : (
                          NA
                        )}
                      </td>
                      <td>
                        <Link to={EndPoints.AGENT} state={val._id}>
                          {val.userName}
                        </Link>
                      </td>
                      <td>{val.name}</td>
                      <td>{val?.phoneNumber}</td>
                      <td>{val.supervisor?.name || NA}</td>
                      {/* <td>{val.manager?.name || NA}</td>
                  <td>
                    <Switch
                      onChange={() => handleBlockUnblock(val)}
                      checked={val.isBlocked}
                      onColor="#3e4396"
                      offColor="#141b2d"
                    />
                  </td>
                  <td>{CreatedAt(val.createdAt)}</td> */}
                      {(all || edit || del) && (
                        <td>
                          {(all || del) && (
                            // <i
                            //   className="material-icons text-danger pe-auto ps-3"
                            //   onClick={() => handleDelete(val._id)}
                            // >
                            //   delete
                            // </i>
                            // <i className="fa-solid fa-ellipsis"></i>
                            <div
                              className="position-relative"
                              // tabIndex={val._id}
                              // onBlur={handleBlur}
                            >
                              <i
                                className="material-icons ps-2 cursior-pointer"
                                onClick={() => handleAction(val._id)}
                              >
                                more_horiz
                              </i>
                              {/* <div className="more-ic"> */}
                              <ul
                                className="icon-list"
                                style={{
                                  display: val?.action ? "block" : "none",
                                }}
                              >
                                <li
                                  onClick={() => handleResetPassword(val._id)}
                                >
                                  Reset Pass
                                </li>
                                <li onClick={() => handleDelete(val)}>
                                  Delete Data
                                </li>
                                <li onClick={() => handleBlockUnblock(val)}>
                                  {val.isBlocked
                                    ? BLOCK_STATUS.BLOCKED
                                    : BLOCK_STATUS.UN_BLOCKED}
                                </li>
                                <li>
                                  <Link
                                    style={{ zIndex: "99" }}
                                    to={EndPoints.EDIT_AGENT}
                                    state={{
                                      ...val,
                                      password: val.dcryptedPass,
                                      parentId: val.supervisor?._id,
                                    }}
                                  >
                                    Edit
                                  </Link>
                                </li>
                              </ul>
                              {/* </div> */}
                            </div>
                          )}
                          {/* {(all || view) && (
                        <Link to={EndPoints.AGENT} state={val._id}>
                          View
                        </Link>
                      )} */}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Paginate
              count={data?.count}
              filter={filter}
              setFilter={setFilter}
              sliceKey={"agent"}
              forcePage={paginate?.agent / 10}
            />
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default Agents;
