import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { HeaderContent } from "../../Helpers/Constants/Headers";
import { EndPoints } from "../../Helpers/Constants/EndPoints";
import { NA, NOT_FOUND } from "../../Helpers/Constants/Default";
import { setAccType } from "../../slices/accTypeSlice";

const Header = ({ collapse }) => {
  // const { profile } = useSelector((val) => val);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const handleLogout = () => {
    localStorage.removeItem("token");
    dispatch(setAccType(0))
  }

  return (
    <div className="container-fluid heading_top">
      <div
        className={`bg-gradient-dark text-white d-flex justify-content-between border-radius-xl rounded mt-3 shadow p-3 header_topbar align-items-center ${
          collapse ? "headeercollapse" : "null"
        }`}
      >
        <span className="d-flex justify-content-between align-items-center">
          <button className="header-back-btn" onClick={() => navigate(-1)}>
            <i className={`fa fa-angle-left`}></i>
          </button>
          {HeaderContent[pathname] || NOT_FOUND}
        </span>
        {/* <h6 className="mb-0 text-white font-weight">
          {profile?.name ? `Welcome ${profile?.name}` : NA}
        </h6> */}
        <Link
          className="d-flex align-items-center"
          to={EndPoints.LOGIN}
          onClick={handleLogout}
        >
          <i className="material-icons opacity-10 text-white">logout</i>
        </Link>
      </div>
    </div>
  );
};

export default Header;
