import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Switch from "react-switch";

import Loader from "../Common/Loader";
import Paginate from "../Common/Paginate";

import { EndPoints } from "../../Helpers/Constants/EndPoints";
import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import { CreatedAt } from "../../Helpers/Universal/Function/common";
import { LIMIT, NA, OFFSET } from "../../Helpers/Constants/Default";
import Permission from "../Common/Permission";
import DatePicker from "../Common/DatePicker";
import { useSelector } from "react-redux";
import DataNotFound from "../Common/DataNotFound";

const SuperAdmins = ({ permissions: { all, add, edit, delete: del } }) => {
    const paginate = useSelector((val) => val.paginate);
    const [data, setData] = useState([]);
    const [visible, setVisible] = useState({ loader: false });
    const [filter, setFilter] = useState({
        limit: LIMIT,
        offset: paginate?.superAdmin || OFFSET,
        // offset:OFFSET
    });

    const getData = async () => {
        try {
            setVisible((prev) => ({ ...prev, loader: true }));
            const { status, data } = await Axios.get(API.SUPERADMIN.LISTING, filter);
            if (status) setData(data);
        } catch (err) {
            console.log("ERROR==>", err);
        } finally {
            setVisible((prev) => ({ ...prev, loader: false }));
        }
    };

    const updateData = async ({ _id, isBlocked }) => {
        try {
            const { status } = await Axios.patch(API.SUPERADMIN.UPDATE, {
                subAdminId: _id,
                isBlocked: !isBlocked,
            });
            if (status)
                setData((prev) => ({
                    ...prev,
                    data: prev?.data?.map((val) =>
                        val._id === _id ? { ...val, isBlocked: !isBlocked } : val
                    ),
                }));
        } catch (err) {
            console.log("ERROR==>", err);
        }
    };

    const setFilters = (event, key) => {
        const value = event?.target?.value;

        value
            ? setFilter((prev) => ({ ...prev, [key]: value }))
            : setFilter((prev) => {
                delete prev[key];
                return { ...prev };
            });
    };

    const exportExcel = async () => {
        try {
            setVisible((prev) => ({ ...prev, loader: true }));
            const { status, data } = await Axios.get(API.REPORT.SUB_ADMIN, filter);

            if (status) window.open(data, "_blank");
        } catch (err) {
            console.log("ERROR==>", err);
        } finally {
            setVisible((prev) => ({ ...prev, loader: false }));
        }
    };

    useEffect(() => {
        getData();
    }, [filter]);

    return (
        <Fragment>
            {visible.loader && <Loader />}
            <div className="container-fluid py-3">
                <div className="flex-both">
                    <div className="d-flex inner_btns">
                        <DatePicker filter={filter} setFilter={setFilter} />
                        <div className="d-flex position-relative bg-gradient-dark rounded mob-mb">
                            <i
                                className="fa fa-search position-absolute top-50 translate-middle ms-3 text-white"
                                aria-hidden="true"
                            ></i>
                            <input
                                onChange={(e) => setFilters(e, "search")}
                                type="search"
                                className="text-start bg-gradient-dark p-2 btn text-lowercase border-0 text-white btn-lg mb-1 mx-4"
                                style={{ cursor: "unset" }}
                                name="gsearch"
                            ></input>
                        </div>
                    </div>
                    <div className="d-flex align-items-center btn-expo">
                        <div className="me-2 mob-margin-none">
                            {(all || add) && (
                                <Link to={EndPoints.ADD_SUPER_ADMIN}>
                                    <button className="btn bg-gradient-dark float-end d-flex btn-md mb-1 align-items-center h-52">
                                        <i className="fa fa-plus me-2 mt-1" aria-hidden="true"></i>
                                        Add
                                    </button>
                                </Link>
                            )}
                        </div>
                    </div>
                </div>

                {data?.data?.length === 0 ? (
                    <DataNotFound />
                ) : (
                    <Fragment>
                        <div className="overflow-table">
                            <table className="page-table">
                                <thead>
                                    <tr>
                                        <th scope="col" className="text-uppercase">
                                            #
                                        </th>
                                        <th scope="col" className="text-uppercase">
                                            Name
                                        </th>
                                        <th scope="col" className="text-uppercase">
                                            Email
                                        </th>
                                        <th scope="col" className="text-uppercase">
                                            Blocked
                                        </th>
                                        <th scope="col" className="text-uppercase">
                                            Permissions
                                        </th>
                                        <th scope="col" className="text-uppercase">
                                            Date
                                        </th>
                                        {(all || edit || del) && (
                                            <th scope="col" className="text-uppercase">
                                                Action
                                            </th>
                                        )}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.data?.map((val, i) => (
                                        <tr key={val._id}>
                                            <td>{++i + filter.offset}</td>
                                            <td>
                                                <Link to={EndPoints.SUPER_ADMIN} state={val._id}>
                                                    {val.name}
                                                </Link>
                                            </td>
                                            <td>{val.email}</td>
                                            <td>
                                                <Switch
                                                    onChange={() => updateData(val)}
                                                    checked={val.isBlocked}
                                                    onColor="#3e4396"
                                                    offColor="#141b2d"
                                                />
                                            </td>
                                            <td>
                                                {val.roleId ? (
                                                    <Permission role={val.roleId} pKey={val._id} setData={setData} />
                                                ) : (
                                                    "No Role"
                                                )}
                                            </td>
                                            <td>{CreatedAt(val.createdAt)}</td>
                                            {(all || edit || del) && (
                                                <td>
                                                    <Link
                                                        to={EndPoints.EDIT_SUPER_ADMIN}
                                                        state={{ ...val, password: val.dcryptedPass }}
                                                    >
                                                        Edit
                                                    </Link>
                                                </td>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                        </div>
                        <Paginate
                            count={data?.count}
                            filter={filter}
                            setFilter={setFilter}
                            sliceKey={"superAdmin"}
                            forcePage={paginate?.superAdmin / 10}
                        />
                    </Fragment>
                )}
            </div>
        </Fragment>
    );
};

export default SuperAdmins;
